import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Utils from 'src/common/Utils';
import useGetWidth from 'src/hooks/useGetWidth';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import Constants from 'src/common/Constants';
import parse from 'html-react-parser';

FootballSkill.propTypes = {
    data: PropTypes.object,
};

export default function FootballSkill(props) {
    const [title, setTitle] = useState(props.data.cfg_title);
    const isMobile = useGetWidth() <= 767;
    const country =
        useSelector((state) => state.headerReducer.country) || Constants.UK;
    useEffect(() => {
        if (isEmpty(props.data?.cfg_title)) return;
        // console.log(props, 'microsite');
        if (props.isMicroSite === true) {
            setTitle(
                props.data.cfg_title.replace(
                    '$AcademyName',
                    props.site.ms_name,
                ),
            );
        }
    }, []);

    // useEffect(() => {
    //     console.log(title, 'title');
    // }, [title]);

    const imageURL = (item) => {
        if (country === Constants.US) {
            return Utils.getThumb(item?.image);
        }
        return Utils.getThumb(item?.icon);
    };
    return (
        // one-training-skill
        <div
            className={`box-football-beginning football-skill ${
                props.noTitle && `one-training-skill`
            } `}
            style={isMobile ? { backgroundColor: 'white' } : {}}>
            <div
                className="container"
                style={{
                    position: 'relative',
                }}>
                <h3 className="heading pdingTop-0 textBlackMobile">{title}</h3>
                <div className="box-beginning-list">
                    <div
                        className="list"
                        style={
                            props.noTitle && !isMobile
                                ? { height: '435px' }
                                : {}
                        }>
                        {props?.data?.cfg_value &&
                            props?.data?.cfg_value?.map((item, index) => {
                                return (
                                    <div className="item" key={index}>
                                        <img
                                            loading="lazy"
                                            alt=""
                                            className="img"
                                            src={imageURL(item)}
                                        />
                                        {!props.noTitle && (
                                            <h3 className="title">
                                                {parse(item['title'])}
                                            </h3>
                                        )}

                                        <p className="description">
                                            {parse(item['des'])}
                                        </p>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
}
