import React from 'react';
import { useSelector } from 'react-redux';
import Constants from 'src/common/Constants';
import PathRoute from 'src/common/PathRoute';

const TextPolicy = () => {
    const country = useSelector((state) => state.headerReducer.country) || Constants.UK;
    return (
        <p className="text-policy">
            For more information about our privacy practices, please read our{' '}
            <a className="link" href={PathRoute.PoliciesPrivacy(country)}>
                Privacy Policy
            </a>
            . <br /> By clicking above, you agree that we may process your
            information in accordance with these terms.
        </p>
    );
};

export default TextPolicy;
