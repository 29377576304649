import Constants from "src/common/Constants";

const COUNTRY = 'country';


class CountryService {
    getCountry() {
        return localStorage.getItem(COUNTRY) || Constants.UK;
    }

    setCountry(value) {
        return localStorage.setItem(COUNTRY,value);
    }
}

export default new CountryService();
